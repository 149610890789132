//
// Use this file to write your own custom SCSS.
//
.mana-logo {
    width: 50px;
}
.mana-logo-sm {
    width: 30px;
}

.sjax {
    padding: 30px 0;
}

.m-auto {
    margin:auto !important;
}